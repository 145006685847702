@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .text-decoration-dashed {
    text-decoration-style: dashed;
  }

  .text-decoration-thin {
    text-decoration-thickness: 1px;
  }
}